import { Tooltip } from '@/components'
import { menuStore, tourStore, useCurrentOrganization } from '@/modules'
import { dashText, useGTM } from '@/tools'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icon as PortoIcon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { useUnit } from 'effector-react'
import { ReactElement, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as S from './styles'

const primaryColor = '#474075'
const disabledColor = '#404040'

interface ButtonProps {
  icon: string
  label: string
  url?: string
  disabled?: boolean
  hidden?: boolean
}

export const Button = ({
  icon,
  label,
  tag,
  url,
  disabled,
  subItems,
}: {
  icon: string
  label: string
  tag?: string
  url?: string
  disabled?: boolean
  subItems?: Array<ButtonProps>
}) => {
  const { setDataLayer } = useGTM()
  const location = useLocation()
  const profile = useCurrentOrganization()
  const [tour, menu] = useUnit([tourStore, menuStore])
  const isRunningTour = !tour.completed
  const [isOpen, setIsOpen] = useState(label === 'Fiança Locatícia' && isRunningTour ? true : false)

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(prev => !prev)
    }
  }

  const handleGTM = ({ name, from }: { name: string; from: string }) => {
    setDataLayer({
      event: 'select_content',
      ev_category: `col:porto:${dashText(location.pathname.substring(1)) || 'dashboard'}`,
      ev_action: `click:menu:${dashText(from || name)}`,
      ev_label: name ? dashText(name) : null,
      timestamp: Date.now().toString(),
      channel: 'col',
      brand: 'porto',
      vertical: 'bank',
      product: dashText(name),
      user_susep: profile.type === 'BROKER' ? profile.metadata.susep.official : '',
      user_sucursal: dashText(profile.name),
      user_logged: true, // como o menu só está presente em área logada, sempre true
    })
  }

  return (
    <S.Container
      key={label + icon}
      $isActive={location.pathname === url}
      $isOpen={isOpen}
      id={label === 'Fiança Locatícia' ? 'tour-id' : undefined}
    >
      <WithTooltip disabled={disabled}>
        <S.Item
          $isActive={location.pathname === url}
          onClick={
            !!subItems?.length ? handleToggle : () => handleGTM({ name: label, from: label })
          }
          as={!!subItems?.length ? 'span' : Link}
          to={!!subItems?.length ? undefined : url || '#'}
        >
          <PortoIcon
            size={24}
            color={disabled ? 'system-primitive-neutral-500' : primaryColor}
            name={disabled ? 'locked' : icon}
          />
          <S.Label
            color={disabled ? 'system-primitive-neutral-500' : primaryColor}
            variant={isOpen ? 'porto-text-body-2-bold' : 'porto-text-body-2-regular'}
            content={label}
          />
          {!!subItems && (
            <S.Chevron>
              <PortoIcon
                size="8px"
                color={disabled ? 'system-primitive-neutral-500' : primaryColor}
                name={isOpen ? 'shortarrowup' : 'shortarrowdown'}
              />
            </S.Chevron>
          )}
          {!!tag && (
            <S.Tag>
              <Typography color="#fff" variant="porto-text-label-bold" content={tag} />
            </S.Tag>
          )}
        </S.Item>
      </WithTooltip>

      {isOpen && (
        <S.SubItemsContainer>
          {subItems
            ?.filter(({ hidden }) => !hidden)
            .map(({ icon, label: subItemLabel, url, disabled }) => (
              <WithTooltip key={`${icon}${label}${url}`} disabled={disabled}>
                <S.Item
                  key={subItemLabel}
                  $isActive={location.pathname === url}
                  $isMenuOpen={menu.isMenuOpen}
                  $isSubItem
                  to={disabled || !url ? '#' : url}
                  onClick={() => handleGTM({ name: subItemLabel, from: label })}
                >
                  <PortoIcon
                    size={16}
                    color={disabled ? 'system-primitive-neutral-500' : primaryColor}
                    name={disabled ? 'locked' : icon}
                  />
                  <S.Label
                    color={disabled ? 'system-primitive-neutral-500' : disabledColor}
                    variant="porto-text-body-2-regular"
                    content={subItemLabel}
                  />
                </S.Item>
              </WithTooltip>
            ))}
        </S.SubItemsContainer>
      )}
    </S.Container>
  )
}

const WithTooltip = ({ children, disabled }: { children: ReactElement; disabled?: boolean }) => {
  return disabled ? (
    <Tooltip
      content="Funcionalidade desabilitada. Peça a seu corretor para ativá-la."
      placement="right"
      delay={[200, 0]}
    >
      {children}
    </Tooltip>
  ) : (
    children
  )
}
