import { Card, Stepper } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import steps from '@/data/fiancaLocaticia/enums/steps.json'

import { useContract } from '@/modules'
import { Contract } from '@/modules/contract/interface'
import OffersDownloadModal from '@/screens/Contracts/Products/Essencial/OffersDownloadModal'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { api } from '@/services'
import { formatCustomerName } from '@/tools'
import { usePartnershipQuery } from '@/tools/hooks/partnership'
import { usePaymentEnable } from '@/tools/hooks/usePaymentEnable'
import { handleOrgId } from '@/tools/utils/handleOrgId'
import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { useMemo } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Resume } from './Resume'
import * as S from './styles'

type Props = {
  currentStep: number
  disableStepper?: boolean
  children: React.ReactNode
}

interface SelectedOptions {
  offers: string[]
  paymentMethods: string[]
}

export const LayoutEssencial: React.FC<Props> = ({ currentStep, disableStepper, children }) => {
  const contract = useContract<Products.ESSENCIAL>()
  const history = useHistory()

  const partnershipsQuery = usePartnershipQuery()

  const settings = partnershipsQuery.data?.find(
    partnership =>
      partnership.suseps.includes(handleOrgId(contract.broker)) &&
      partnership.partner.id === handleOrgId(contract.partner),
  )?.settings

  const paymentEnable = usePaymentEnable(
    'porto_essencial',
    contract.budgets?.length ? contract.budgets[0].id : undefined,
    settings,
  )

  const { pageTitle } = steps[currentStep - 1]

  const customerName = useMemo(() => {
    const customers = contract?.payload?.customers

    if (!customers?.length) return

    const first = customers[0]

    return formatCustomerName(first.socialName || first.name)
  }, [contract?.payload?.customers])

  const subtitle = useMemo(() => {
    const proposal = contract?.policy?.proposal && Number(contract.policy?.proposal)

    if (!proposal) return customerName

    return `Proposta ${proposal} - ${customerName}`
  }, [contract?.policy?.proposal, customerName])

  const handleDownloadOffers = useMutation({
    mutationFn: (params: SelectedOptions) =>
      api.contracts.print(contract.id, 'BUDGET', params.offers, params.paymentMethods),
  })

  function handleEdit() {
    history.push('/contratos/novo/essencial', {
      id: contract.id,
      broker: contract.broker,
      partner: contract.partner,
      payload: contract.payload,
    } as Partial<Contract<Products.ESSENCIAL>>)
  }

  return (
    <S.Container>
      <S.Header>
        <S.Col>
          <Typography as="span" variant="porto-title-4-bold">
            {pageTitle}
          </Typography>
          {currentStep !== 1 && (
            <Typography as="span" variant="porto-title-6-semibold">
              {subtitle}
            </Typography>
          )}
        </S.Col>
        {currentStep !== 1 && (
          <Flex sx={{ gap: '16px', flexDirection: { md: 'row' } }}>
            {!contract?.policy?.id && (
              <Button kind="ghost" onClick={handleEdit} data-test-id="budget-edit">
                Editar orçamento
              </Button>
            )}

            {currentStep === 2 && !!contract?.budgets?.length && (
              <Button
                isLoading={handleDownloadOffers.isLoading}
                isDisabled={handleDownloadOffers.isLoading}
                onClick={async () => {
                  const result: SelectedOptions = await OffersDownloadModal({
                    offers: contract.budgets.map(budget => ({
                      label: budget.description?.toLocaleLowerCase(),
                      value: budget.id,
                    })),
                    paymentMethods: paymentEnable.map(payment => ({
                      label: payment.description.toLowerCase(),
                      value: payment.id,
                    })),
                  })

                  handleDownloadOffers.mutate(result)
                }}
                kind="ghost"
                data-test-id="offers-download"
              >
                Baixar ofertas em PDF
              </Button>
            )}
          </Flex>
        )}
      </S.Header>
      <S.Row>
        <S.Col>
          {!disableStepper && (
            <Card>
              <Stepper currentStep={currentStep} steps={steps} />
            </Card>
          )}
          <S.ChildrenContainer>{children}</S.ChildrenContainer>
        </S.Col>
        {!!contract?.id && currentStep !== 1 && <Resume />}
      </S.Row>
    </S.Container>
  )
}
