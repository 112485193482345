import { Conditional } from '@/components'
import { useCurrentOrganization } from '@/modules'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { CardSac } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { usePartnershipQuery } from '@/tools/hooks/partnership'
import { isHandoutEnabled, isRentInstallmentEnabled } from '../../utils'
import { Container, List } from './styles'

const Sinistro = () => {
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'

  const partnershipsQuery = usePartnershipQuery()

  return (
    <Container>
      {/* {partnershipsQuery.isFetching && <Loading />} */}
      <Typography
        color="system-primitive-neutral-900"
        variant="porto-title-4-bold"
        content="Sinistro"
      />
      <List>
        <Conditional when={isBroker || isRentInstallmentEnabled(partnershipsQuery.data)}>
          <CardSac
            icon="holdingcard"
            upperTitle="Parcelamento de aluguel"
            title="Sua imobiliária recebe o valor à vista quando o cliente parcelar o aluguel!"
            description="Clientes do Fiança Locatícia podem pagar no cartão de crédito à vista ou em até 10 vezes, aluguéis e encargos vencidos e a vencer desde que não tenha sinistro aberto. O parcelamento também pode ser oferecido no momento da rescisão."
            actions={[
              {
                label: 'Parcelar aluguel',
                to: '/sinistro/parcelamento-aluguel',
              },
            ]}
            sx={{ flex: 1 }}
          />
        </Conditional>

        <Conditional when={!isBroker}>
          <CardSac
            icon="handcoins2"
            upperTitle="assessoria de cobrança"
            title="O cliente está no primeiro mês de atraso? Deixa com a gente!"
            description="Faremos comunicações por SMS para avisar sobre o atraso e como o cliente pode fazer para quitar! Importante: após pagamento, informe para que possamos encerrar os envios."
            actions={[{ label: 'Acessar', to: '/sinistro/assessoria-de-cobranca' }]}
            sx={{ flex: 1 }}
          />
        </Conditional>

        <CardSac
          icon="residencealert"
          upperTitle="sinistro"
          title="Seu inquilino não pagou o aluguel e não deseja parcelar?"
          description="O sinistro deverá ser comunicado logo após o vencimento do 2º aluguel e ou encargo não pago."
          actions={[
            {
              label: 'Comunicar sinistro',
              to: '/sinistro/comunicado-sinistro',
              hidden: !(isBroker || isHandoutEnabled(partnershipsQuery.data)),
            },
            {
              label: 'Acompanhar sinistros',
              to: '/sinistro/consulta-sinistro',
            },
          ]}
          sx={{ flex: 1 }}
        />
      </List>
    </Container>
  )
}

export default Sinistro
