import React, { useRef } from 'react'
import { isBrowser, isMobile } from 'react-device-detect'

import { useOutsideClick } from '@/tools'

import { createModal } from '../promised'

import { IConfirmationProps } from './interfaces'

import {
  Container,
  Dialog,
  Head,
  Icon,
  Title,
  Body,
  Content,
  Footer,
  Action,
} from './styles'

const variants = {
  hidden: { y: 40 },
  visible: {
    y: 0,
    transition: { ease: [0.65, 0, 0.35, 1], duration: 0.4 },
  },
}

const Confirmation: React.FC<IConfirmationProps> = (props) => {

  const ref = useRef()

  return (
    <Container centered={isBrowser} bottom={isMobile} kind={props.kind}>
      <Dialog ref={ref} initial="hidden" animate="visible" variants={variants}>
        <Head>
          {props.icon &&
            <Icon name={props.icon} />
          }
          <Title style={props.titleStyle}>{props.title}</Title>
        </Head>

        <Body>
          <Content
            style={props.contentStyle}
            dangerouslySetInnerHTML={{ __html: props.content as any }}
          />

          <Footer>
            {!props.removeDismissButton && (
              <Action
                kind="ghost"
                onClick={props.onDismiss}
                {...props?.gtmType?.dismissText}
              >
                {props.dismissText}
              </Action>
            )}

            <Action
              variant="primary"
              onClick={props.onSubmit}
              {...props?.gtmType?.submitText}
            >
              {props.submitText}
            </Action>
          </Footer>
        </Body>
      </Dialog>
    </Container>
  )
}

Confirmation.defaultProps = {
  kind: 'danger',
  dismissText: 'Cancelar',
}

export default createModal<IConfirmationProps>(Confirmation)
