/* eslint-disable */

import api from '@/services/api'
import { usePartnershipQuery } from '@/tools/hooks/partnership/usePartnershipQuery'
import { useQuery } from 'react-query'

export const useIsCapitalizacaoEnabled = (profile): { result: boolean; isLoading: boolean } => {
  if (profile.type === 'BROKER') return { result: true, isLoading: false }

  const partnershipsQuery = usePartnershipQuery()

  const isEnabled = partnershipsQuery.data?.some(
    partnership => partnership.settings['porto_capitalizacao.enabled'],
  )

  return { result: isEnabled, isLoading: partnershipsQuery.isFetching }
}

export const useIsCapitalizacaoPaymentEnabled = ({
  profile,
  susep,
}): { creditCard: boolean; bankSlip: boolean; isLoading: boolean } => {
  if (profile.type === 'BROKER') return { creditCard: true, bankSlip: true, isLoading: false }

  const partnershipsQuery = usePartnershipQuery()

  if (!susep) {
    return partnershipsQuery.data?.some(
      partnership =>
        partnership.settings['porto_capitalizacao.payments.creditCard.enabled'] &&
        partnership.settings['porto_capitalizacao.payments.bankSlip.enabled'],
    )
  }

  const partnership = partnershipsQuery.data?.find(p => p.suseps.includes(susep))

  return {
    creditCard: partnership?.settings['porto_capitalizacao.payments.creditCard.enabled'],
    bankSlip: partnership?.settings['porto_capitalizacao.payments.bankSlip.enabled'],
    isLoading: partnershipsQuery.isFetching,
  }
}
