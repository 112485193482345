import { Forms, Grid } from '@/components'
import { SocialNameField } from '@/components/Forms/SocialNameField'
import { AnimateVerticalShow } from '@/components/Utilities/Animations/AnimateVerticalShow'
import { getByDocument } from '@/services/api/customers'
import { useFeatureEnable } from '@/tools'
import { Box, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { RadioGroup } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { isCPF } from 'brazilian-values'
import { useField } from 'formik'
import { useEffect, useState } from 'react'
import { BaseCard } from '../../../../components/BaseCard'

export const Owner = ({ addressType }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showFields, setShowFields] = useState(false)
  const [{ value }, _, { setValue: setPropertyType }] = useField('landlord.address_type')
  const [{ value: landlordValue }, meta, { setValue: setLandlordValue }] = useField('landlord')

  useEffect(() => {
    const handleLandlordBureauInfo = async () => {
      try {
        setIsLoading(true)
        const landlordBureauData = await getByDocument(landlordValue.document)
        setLandlordValue({
          ...landlordValue,
          name: landlordBureauData.name,
          phone: landlordBureauData.phone,
          birthdate: landlordBureauData.birthdate,
        })
      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false)
        setShowFields(true)
      }
    }

    if (landlordValue.document.length === 14 && !meta?.error?.document) {
      handleLandlordBureauInfo()
    }
  }, [landlordValue.document, meta?.error?.document])

  const featureEnable = useFeatureEnable()

  return (
    <BaseCard
      title="Proprietário"
      subtitle="Os dados do proprietário não são compartilhados com o inquilino."
    >
      <Grid gap="16px">
        <Typography content="Dados pessoais do proprietário" variant="porto-title-6-medium" />

        <Grid columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}>
          <Forms.InputGroup
            name="landlord.document"
            label="CPF"
            placeholder="000.000.000-00"
            mask="cpf"
            readOnly={isLoading}
            loading={isLoading}
            data-test-id="owner-cpf"
          />
        </Grid>

        <AnimateVerticalShow isOpen={!isLoading && showFields}>
          <Grid gap="16px">
            <Grid columns={['1fr', '2fr 1fr']}>
              <Forms.InputGroup
                data-test-id="owner-name"
                name="landlord.name"
                label="Nome Completo"
                placeholder="Digite"
              />
            </Grid>

            {featureEnable.socialNameProducts.socialNameCapitalizacao && (
              <AnimateVerticalShow isOpen={isCPF(landlordValue.document)}>
                <SocialNameField
                  textFieldName="landlord.socialName"
                  toggleName="landlord.hasSocialName"
                  data-test-id="owner-social-name"
                />
              </AnimateVerticalShow>
            )}

            <Grid gap="16px" columns={['1fr', '1fr 2fr']}>
              <Forms.InputGroup
                name="landlord.phone"
                label="Telefone ou Celular"
                placeholder="(00) 90000-0000"
                mask="phone"
                data-test-id="owner-phone"
              />

              <Forms.InputGroup
                name="landlord.email"
                label="E-mail"
                placeholder="nome@email.com.br"
                data-test-id="owner-email"
              />
              <Forms.InputGroup
                name="landlord.birthdate"
                label="Data de Nascimento"
                placeholder="dd/mm/aaaa"
                mask="date"
                data-test-id="owner-date-birth"
              />
            </Grid>
          </Grid>
        </AnimateVerticalShow>

        <Typography content="Endereço do proprietário" variant="porto-title-6-medium" />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', lg: 'repeat(3, 1fr)' },
            gap: '1rem',
          }}
        >
          <Forms.InputGroup
            name="landlord.address.zipcode"
            label="CEP"
            placeholder="00000-000"
            mask="zipcode"
            data-test-id="owner-cep"
          />
          <Forms.InputGroup
            name="landlord.address.number"
            label="Número"
            type="number"
            placeholder="Informe o número"
            data-test-id="owner-number"
          />
        </Box>
        <RadioGroup
          titleProps={{ content: 'Tipo de imóvel' }}
          initialChecked={value || 'RESIDENCIAL'}
          options={addressType.map(({ label, value }) => ({
            text: label,
            value,
          }))}
          onChange={setPropertyType}
        />
      </Grid>
    </BaseCard>
  )
}
