import React, { useContext, useReducer } from 'react'
import { mergeWith } from 'lodash'
import { isCNPJ } from 'brazilian-values'

export const ENUMS = {
  COMPANY_TYPE: {
    public: 'Pública',
    private: 'Privada',
  },
  PATRIMONY: {
    '001': 'Sem patrimônio',
    '002': 'Até R$ 1.200,00',
    '003': 'De R$ 1200,01 até R$ 10.499,99',
    '004': 'De R$ 10.500,00 até R$ 60.000,00',
    '005': 'Acima de R$ 60.000,00',
  },
  OPTING_SIMPLES_NACIONAL: {
    1: 'Sim',
    0: 'Não',
  },
}

export const actionTypes = {
  UPDATE_FORM_FROM_API: 'UPDATE_FORM_FROM_API',
  UPDATE_FORM_ATTRIBUTES: 'UPDATE_FORM_ATTRIBUTES',
  UPDATE_BEHAVIOR: 'UPDATE_BEHAVIOR',
}

export type AddressState = {
  number: string | number
  zipcode: string | number
  complement: string
  state: string
  street: string
  city: string
  neighborhood: string
}

export type AttributesState = {
  bankData: {
    bank: string | number
    agency: string | number
    account: string | number
    accountDigit: string | number
    email: string
  }
  insuranceAgent: {
    id: string | number
    name: string
    email: string
    susep: string | number
    telephone: string
    address: {
      city: string
      complement: string
      neighborhood: string
      number: string | number
      state: string
      street: string
      zipcode: string
    }
  }
  policyholder: {
    type: string
    address: AddressState
    document: string
    email: string
    phone: string
    lgpd: boolean
    termsOfUse: boolean
    name: string
    creci: string
    companyName: string
    companyType: string
    cityRegistration: string
    patrimony: string
    optingSimplesNacional: boolean
    representative: {
      cpf: string
      name: string
      email: string
      foreign: boolean
      address: AddressState
    }
  }
}

export type State = {
  behavior: {
    hasBroker: boolean
    selectedBrokers: string[]
  }
  form: {
    id?: string
    application: string
    attributes: AttributesState
  }
}

const initialState: State = {
  behavior: {
    hasBroker: false,
    selectedBrokers: [],
  },
  form: {
    id: undefined,
    application: 'solucoesparalocacao',
    attributes: {
      bankData: {
        bank: undefined,
        agency: undefined,
        account: undefined,
        accountDigit: undefined,
        email: undefined,
      },
      insuranceAgent: {
        id: undefined,
        name: undefined,
        email: undefined,
        susep: undefined,
        telephone: undefined,
        address: {
          city: undefined,
          complement: undefined,
          neighborhood: undefined,
          number: undefined,
          state: undefined,
          street: undefined,
          zipcode: undefined,
        },
      },
      policyholder: {
        type: undefined,
        address: {
          number: undefined,
          zipcode: undefined,
          complement: undefined,
          state: undefined,
          street: undefined,
          city: undefined,
          neighborhood: undefined,
        },
        document: undefined,
        email: undefined,
        phone: undefined,
        lgpd: undefined,
        termsOfUse: undefined,
        name: undefined,
        creci: undefined,
        companyName: undefined,
        companyType: undefined,
        cityRegistration: undefined,
        patrimony: undefined,
        optingSimplesNacional: undefined,
        representative: {
          cpf: undefined,
          name: undefined,
          email: undefined,
          foreign: undefined,
          address: {
            number: undefined,
            zipcode: undefined,
            complement: undefined,
            state: undefined,
            street: undefined,
            city: undefined,
            neighborhood: undefined,
          },
        },
      },
    },
  },
}

export const reducer = (state: State, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_FORM_FROM_API: {
      return mergeWith(state, { form: action.payload })
    }

    case actionTypes.UPDATE_FORM_ATTRIBUTES: {
      return mergeWith(state, { form: { attributes: action.payload } })
    }

    case actionTypes.UPDATE_BEHAVIOR: {
      return mergeWith(state, { behavior: action.payload })
    }

    default: {
      throw new Error('You fired an inexistent action!')
    }
  }
}

const OnboardingContext = React.createContext(null)
export const useOnboarding = () =>
  useContext<{
    state: State
    [key: string]: any
  }>(OnboardingContext)

export const getters = (state: State) => {
  return {
    hasBrokerSelected: () => !!state.form.attributes.insuranceAgent.id,
    partnerShape: () => {
      return {
        name: state.form.attributes.policyholder.name,
        creci: state.form.attributes.policyholder.creci,
        broker: state.form.attributes.insuranceAgent.susep,
        email: state.form.attributes.policyholder.email,
        phone: `+55${state.form.attributes.policyholder.phone.replace(
          /\D/g,
          ''
        )}`,
        document: state.form.attributes.policyholder.document,
        address: {
          ...state.form.attributes.policyholder.address,
          number: String(state.form.attributes.policyholder.address.number),
        },
        bank: {
          name: state.form.attributes.bankData.bank,
          branch: state.form.attributes.bankData.agency,
          account: state.form.attributes.bankData.account,
          digit: state.form.attributes.bankData.accountDigit,
          bank_email: state.form.attributes.bankData.email,
        },
        company: isCNPJ(state.form.attributes.policyholder.document)
          ? {
              patrimony: state.form.attributes.policyholder.patrimony,
              administrator: {
                name: state.form.attributes.policyholder.representative.name,
                address: {
                  ...state.form.attributes.policyholder.representative.address,
                  number: String(
                    state.form.attributes.policyholder.representative.address
                      .number
                  ),
                },
                email: state.form.attributes.policyholder.representative.email,
                document: state.form.attributes.policyholder.representative.cpf,
              },
              municipal_inscription:
                state.form.attributes.policyholder.cityRegistration,
              private:
                state.form.attributes.policyholder.companyType ===
                ENUMS.COMPANY_TYPE.private,
              simples_nacional: Boolean(
                Number(state.form.attributes.policyholder.optingSimplesNacional)
              ),
            }
          : undefined,
      }
    },
    selectedBrokers: () => state.behavior.selectedBrokers,
  }
}

export const OnboardingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <OnboardingContext.Provider
      value={{
        state,
        getters: getters(state),
        dispatch,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}
