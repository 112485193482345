import { contractStatus, statusValues } from '@/data/capitalizacao'
import { useContract } from '@/modules'
import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system'
import { formatToPhone } from 'brazilian-values'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TextContentGroup } from '../TextContentGroup'
import { handleButtons } from './handleButtons'

const handleContractStatus = contract => {
  if (
    contract.metadata.payment_link &&
    contract.metadata.status === contractStatus.AWAITING_PAYMENT
  ) {
    return contractStatus.AWAITING_PAYMENT_LINK
  }

  return contract.metadata.status
}

export const Info = () => {
  const [isLoading, setIsLoading] = useState(false)
  const contract = useContract()
  const history = useHistory()
  const _contractStatus = handleContractStatus(contract)
  const statusInfoValues = statusValues[_contractStatus]?.info

  const actions = handleButtons({
    contract,
    history,
    isLoading,
    setIsLoading,
  })[_contractStatus]

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1rem',
        padding: '1.25rem 1rem',
        backgroundColor: 'porto-primitive-off-white',
        borderRadius: '8px',
        minWidth: { xs: '100%', lg: '460px' },
        width: 'fit-content',
      }}
      data-test-id={'payment-details'}
    >
      <Icon name={statusInfoValues.icon} size="2rem" color="porto-banking-primary" />
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '.5rem',
        }}
      >
        <Typography variant="porto-text-body-1-bold" content={statusInfoValues.title} />
        <Typography variant="porto-text-body-2-regular" content={statusInfoValues.description} />
        {_contractStatus === contractStatus.REGISTERING_ERROR && (
          <ul style={{ marginLeft: '2rem' }}>
            {contract.metadata.blocking_issues.map(({ nm_descricao: error }) => (
              <li>
                <Typography variant="porto-text-body-2-regular" content={error} />
              </li>
            ))}
          </ul>
        )}
      </Flex>
      {[contractStatus.AWAITING_PAYMENT, contractStatus.ISSUED].includes(_contractStatus) && (
        <TextContentGroup
          values={[
            [
              {
                title: 'Cliente',
                description:
                  contract.payload?.customer?.socialName || contract.payload.customer.name,
              },
              {
                title: 'Telefone/celular',
                description: formatToPhone(contract.payload.customer.phone) || '-',
              },
              { title: 'E-mail', description: contract.payload.customer.email },
            ],
          ]}
        />
      )}
      {actions && (
        <Flex
          sx={{
            gap: '.75rem',
            flexWrap: 'wrap',
          }}
        >
          {actions}
        </Flex>
      )}
    </Flex>
  )
}
