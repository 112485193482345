export interface Product {
  path: string
  title: string
  description: string
  subroute: Subroute[]
}

export interface Subroute {
  path: string
  title: string
  label?: string
  description: string
  fields: string[]
}

const imobiliaria: Product = {
  path: '/imobiliaria',
  title: 'Seguro Incêndio',
  description:
    'Oferece cobertura obrigatória por lei, contra incêndio, para imóveis alugados.',
  subroute: [
    {
      path: '/residencial',
      title: 'Plano residencial',
      description: 'Parametrize os campos do plano residencial.',
      fields: [
        'porto_imobiliaria.residential.enabled',
        'porto_imobiliaria.residential.coverages',
        'porto_imobiliaria.residential.service_clauses',
        'porto_imobiliaria.residential.comission',
        'porto_imobiliaria.residential.prolabore',
      ],
    },
    {
      path: '/comercial',
      title: 'Plano comercial',
      description: 'Parametrize os campos do plano comercial.',
      fields: [
        'porto_imobiliaria.commercial.enabled',
        'porto_imobiliaria.commercial.coverages',
        'porto_imobiliaria.commercial.service_clauses',
        'porto_imobiliaria.commercial.comission',
        'porto_imobiliaria.commercial.prolabore',
      ],
    },
    {
      path: '/suseps',
      title: 'Suseps',
      description: 'Escolha quais suseps estarão habilitadas.',
      fields: ['porto_imobiliaria.suseps'],
    },
    {
      path: '/pagamentos',
      title: 'Formas de pagamento',
      description:
        'Selecione quais formas de pagamentos estarao disponíveis para emissão nos orçamentos.',
      fields: ['porto_imobiliaria.payments'],
    },
    {
      path: '/lote',
      title: 'Orçamento em lote',
      description:
        'Habilite o orçamento em lote para sua imobiliária parceira. Importante lembrar que as configurações feitas para os orçamentos individuas não irão se aplicar no orçamento em lote.',
      fields: [
        'porto_imobiliaria.batch.enabled',
        'porto_imobiliaria.batch.comission.percentage',
        'porto_imobiliaria.batch.prolabore',
      ],
    },
  ],
}

const aluguel: Product = {
  path: '/aluguel',
  title: 'Fiança Locatícia',
  description:
    'Substitui com vantagens o papel do fiador, com vigência que acompanha o contrato de locação.',
  subroute: [
    {
      path: '/essencial',
      title: 'Essencial',
      label: 'Seguro Essencial',
      description: 'Parametrize os campos do Essencial',
      fields: ['porto_essencial.payments', 'porto_essencial.claim'],
    },
    {
      path: '/basico',
      title: 'Plano básico',
      description: 'Parametrize os campos do plano básico.',
      fields: [
        'porto_aluguel.basic.comission',
        'porto_aluguel.basic.coverages',
      ],
    },
    {
      path: '/tradicional',
      title: 'Plano tradicional',
      description: 'Parametrize os campos do plano tradicional.',
      fields: [
        'porto_aluguel.traditional.comission',
        'porto_aluguel.traditional.coverages',
      ],
    },
    {
      path: '/universitario',
      title: 'Plano universitário',
      description: 'Parametrize os campos do plano universitário.',
      fields: [
        'porto_aluguel.university.comission',
        'porto_aluguel.university.coverages',
      ],
    },
    {
      path: '/suseps',
      title: 'Suseps',
      description: 'Escolha quais suseps estarão habilitadas.',
      fields: ['porto_aluguel.suseps'],
    },
  ],
}

const capitalizacao: Product = {
  path: '/capitalizacao',
  title: 'Título de capitalização',
  description:
    'Garantia do contrato de locação para pagamento do aluguel. Sem análise cadastral e resgate de até 100% do valor contratado!',
  subroute: [
    {
      path: '/produto',
      title: 'Título de capitalização',
      description: 'Habilitar ou desabilitar o Produto.',
      fields: ['porto_capitalizacao.enabled'],
    },
    {
      path: '/suseps',
      title: 'Suseps',
      description: 'Escolha quais suseps estarão habilitadas.',
      fields: ['porto_capitalizacao.suseps'],
    },
    {
      path: '/pagamentos',
      title: 'Formas de pagamento',
      description:
        'Selecione quais formas de pagamentos estarão disponíveis para emissão nos orçamentos.',
      fields: ['porto_capitalizacao.payments'],
    },
  ],
}

const exportedProducts = [imobiliaria, aluguel, capitalizacao]

export default exportedProducts
