import React from 'react'
import { useHistory } from 'react-router-dom'

import { Icons } from '@/components'

import * as S from './styles'
import { HeadingProps } from './types'

const Heading = ({
  title,
  description,
  removeBackButton = false,
  controls,
  backLink,
}: HeadingProps) => {
  const history = useHistory()

  const onClick = () => {
    if (backLink) {
      history.push(backLink)

      return
    }

    history.goBack()
  }

  return (
    <S.Container>
      <S.Content>
        {!removeBackButton && (
          <S.Back onClick={onClick} data-test-id="back">
            <Icons.FeatherIcons name="ArrowLeft" />
          </S.Back>
        )}

        <S.Title>{title}</S.Title>

        <S.Description>{description}</S.Description>
      </S.Content>

      {controls && <S.Controls>{controls}</S.Controls>}
    </S.Container>
  )
}

export default Heading
