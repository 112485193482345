import React from 'react'

import { closeModal, render } from './actions'

import { queryClient } from '@/index'
import { QueryClientProvider } from 'react-query'
import { PromisedModalProps, TCreateModal } from './types'

let modalCounter = 0

export default function createModal<TModalComponentProps extends PromisedModalProps>(
  Modal: React.ElementType<TCreateModal>,
) {
  return (props?: TModalComponentProps) => {
    const modalIndex = modalCounter++

    const handler = new Promise<Parameters<TModalComponentProps['onSubmit']>[0]>(resolve => {
      const onSubmit = value => {
        resolve(value)
        closeModal(modalIndex)
      }

      const onDismiss = () => {
        resolve(undefined)
        closeModal(modalIndex)
      }

      render({
        content: (
          <QueryClientProvider client={queryClient}>
            <Modal onSubmit={onSubmit} onDismiss={onDismiss} {...props} />
          </QueryClientProvider>
        ),
        index: modalIndex,
      })
    })

    handler.finally(() => {
      closeModal(modalIndex)
    })

    return handler
  }
}
