import React, { lazy, Suspense, useCallback, useContext } from 'react'

import schema from '../../schema'

import { Subroute } from '../../products'

import { SettingsContext } from '..'
import * as S from './styles'
import { dashText } from '@/tools'

const Components = {
  Input: lazy(() => import('@/components/Forms/InputGroup')),
  Checkbox: lazy(() => import('../../components/Checkbox')),
  Select: lazy(() => import('@/components/Forms/SelectGroup')),
  Toggle: lazy(() => import('@/components/Forms/Toggle')),
}

type FormProps = Pick<Subroute, 'fields'>

const Form = ({ fields = [] }: FormProps): any => {
  const context = useContext(SettingsContext)

  const runCallableAttributes = useCallback(
    (obj) => {
      return Object.entries(obj).reduce((prev, [key, value]) => {
        return {
          ...prev,
          [key]: typeof value === 'function' ? value(context.helpers) : value,
        }
      }, {})
    },
    [context]
  )

  return fields.map((field) => {
    if (
      context.organization.type === 'REAL_ESTATE' &&
      context.viewOnly.fields.includes(field)
    ) {
      return null
    }

    if (
      (field === 'porto_imobiliaria.residential.prolabore' ||
        field === 'porto_imobiliaria.commercial.prolabore' ||
        field === 'porto_imobiliaria.batch.prolabore') &&
      !context.details.prolabore_enabled
    ) {
      return null
    }

    const fieldSchema = schema[field]

    const fieldRenderer = (name, field) =>
      React.createElement(
        {
          input: Components.Input,
          select: Components.Select,
          toggle: Components.Toggle,
          checkbox: Components.Checkbox,
        }[field.type],
        runCallableAttributes({
          ...field,
          metadata: {
            ...context?.metadata,
            user: context.user,
          },
          name,
          resetValueOnUnmount: false,
          ...(context.viewOnly.enabled && {
            disabled: true,
          }),
        }),
      )

    if (Array.isArray(fieldSchema)) {
      return fieldSchema.map((schema, index) => (
        <>
          {schema?.upperHeading && <S.UpperHeading>{schema?.upperHeading}</S.UpperHeading>}
          <S.Container
            key={index}
            viewOnly={context.viewOnly.enabled}
            data-test-id={dashText(schema.heading)}
          >
            {context.viewOnly.enabled && <S.LockIcon />}

            <S.Heading>
              <S.Title>{schema.heading}</S.Title>
              {schema.description && <S.Description>{schema.description}</S.Description>}
            </S.Heading>

            {Object.entries(schema.fields).map(([name, nestedField]: any, index) => {
              return (
                <S.Content key={index} data-test-id={dashText(nestedField.title)}>
                  <S.Heading removeMargin data-test-id="config">
                    <S.Title smaller>{nestedField.title}</S.Title>
                    {nestedField.description && (
                      <S.Description smaller>{nestedField.description}</S.Description>
                    )}
                  </S.Heading>
                  <div data-test-id="valor">
                    <Suspense fallback={<p>carregando...</p>}>
                      {fieldRenderer(name, nestedField)}
                    </Suspense>
                  </div>
                </S.Content>
              )
            })}
          </S.Container>
        </>
      ))
    }

    return (
      <S.Container viewOnly={context.viewOnly.enabled} data-test-id={dashText(fieldSchema?.title)}>
        {context.viewOnly.enabled && <S.LockIcon />}
        <S.Content>
          <S.Heading removeMargin data-test-id="config">
            <S.Title>{fieldSchema?.title}</S.Title>
            <S.Description>{fieldSchema?.description}</S.Description>
          </S.Heading>

          <div data-test-id="valor">
            <Suspense fallback={<p>carregando...</p>}>{fieldRenderer(field, fieldSchema)}</Suspense>
          </div>
        </S.Content>
      </S.Container>
    )
  })
}

export default Form
