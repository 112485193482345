import { useCurrentOrganization } from "@/modules"
import { isBroker } from "../partnership"

export const handleInstallments = (settings, isBroker: boolean) => {
  const NUMBER_OF_INSTALLMENTS_WITHDOWN_PAYMENT_MAX = 12
  const NUMBER_OF_INSTALLMENTS_JUST_BANK_SLIP = 1
  let invoiceWithoutDown = 0
  let invoiceWithDown = 0

  if (settings['porto_imobiliaria.payments.invoiceWithoutDown.enabled']) invoiceWithoutDown = settings['porto_imobiliaria.payments.invoiceWithoutDown.installments']
  if (settings['porto_imobiliaria.payments.invoiceWithDown.enabled']) invoiceWithDown = settings['porto_imobiliaria.payments.invoiceWithDown.installments']


  if(isBroker){
    invoiceWithoutDown = NUMBER_OF_INSTALLMENTS_WITHDOWN_PAYMENT_MAX
  }

  if(invoiceWithoutDown === 0 && invoiceWithDown === 0) return NUMBER_OF_INSTALLMENTS_JUST_BANK_SLIP

  const installments = Math.max(invoiceWithDown, invoiceWithoutDown)
  return installments
}