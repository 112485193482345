import { useCurrentOrganization } from '@/modules'
import { loadRealEstateProductPartnershipSuseps } from '@/services/api/partnership'
import { PartnershipSusep, PartnershipSuseps } from '@/services/api/partnership/types'
import { useField } from 'formik'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { AutocompleteLocal } from '../AutocompleteLocal'
import { ISupepCombo } from './interfaces'

export const SupepCombo = ({
  product = 'any',
  sx,
  selectionCallback = () => {},
  error = false,
  errorMessage = '',
  disabled = false,
}: ISupepCombo) => {
  const [options, setOptions] = useState<PartnershipSuseps>([])
  const [value, setValue] = useState(null)
  const profile = useCurrentOrganization()
  const requiredProfile = 'REAL_ESTATE'

  const [field, meta, helpers] = useField('susep')

  const handleChange = (newValue: PartnershipSusep | null) => {
    setValue(newValue)
    selectionCallback(newValue)
    helpers.setValue(newValue?.susep)
  }

  const susepOptions = useQuery(
    `partnerships.real-estate.suseps.${product}`,
    () => loadRealEstateProductPartnershipSuseps(product),
    {
      // ? Tempo de vida do dado antes de uma nova busca 5min.
      staleTime: 5 * 60 * 1000,
    },
  )

  useEffect(() => {
    if (profile.type !== requiredProfile) return

    setOptions(susepOptions.data)

    if (field.value) {
      const selected = susepOptions.data?.find(e => e.susep === field.value)
      handleChange(selected)
    }
  }, [profile.type, susepOptions.data])

  return (
    <AutocompleteLocal<PartnershipSusep>
      label="SUSEP"
      id="susep-combo"
      getOptionLabel={(option: PartnershipSusep) => `${option.susep} - ${option.broker.name}`}
      isOptionEqualToValue={(option: PartnershipSusep, value: PartnershipSusep | null) =>
        option.susep === value?.susep
      }
      disabled={disabled}
      error={error}
      errorMessage={errorMessage}
      loading={susepOptions.isFetching}
      options={options}
      selectionCallback={handleChange}
      sx={sx}
      value={value}
    />
  )
}
