import { createEvent, createStore, Event } from 'effector'
import { ReactElement } from 'react'

export const render: Event<{ content: ReactElement; index: number }> = createEvent('render')
export const closeModal = createEvent<number>('closeModal')
export const clearPromissedModals = createEvent('clearModals')

const store = createStore<ReactElement[]>([])
  .on(render, (state, payload) => {
    const modals = [...state]
    modals[payload.index] = payload.content

    return modals
  })
  .on(closeModal, (state, index) => state.filter((_, i) => `${i}` !== `${index}`))
  .on(clearPromissedModals, () => [])

export default store
